<template>
  <div class="home">
    <div class="fixed">
      <div class="header">
        <div>
          音频列表：
          <el-select size="small" style="margin-right: 20px; width: 300px;" v-model="currentAudio" placeholder="请选择">
            <el-option
              v-for="(item, index) in audioList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <el-button size="small" v-btn @click="chooseAudio">确认加载</el-button>
          <span style="margin-left: 16px">共 {{total}} 条数据</span>
        </div>
        <div class="top">
          <div class="top-avatar">
            <el-avatar size="medium" :src="circleUrl" class="top-avatar_img"></el-avatar>
            <div>{{username}}</div>
            <el-dropdown class="showList">
          <span class="el-dropdown-link" style="color: #304152">
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="logout">
                    退出登录
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div v-if="!loadAudio" class="empty_tips">请先在上方选择您需要加载的音频文件</div>
      <div id="waveform"></div>
      <div id="wave-timeline"></div>
      <br>
      <div class="control-wrap">
        <div class="wrap">
          <el-button v-btn :disabled="!loadAudio" size="mini" :autofocus="false" type="primary" @click="play">{{isStart ? '暂停' : '播放'}}</el-button>
          <el-button v-btn :disabled="!loadAudio" size="mini" type="danger" @click="stop">结束</el-button>
          <el-dropdown :disabled="!loadAudio" class="select-speed" @command="handleCommand" split-button size="mini" type="">
            {{currentSpeed}}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="i in speeds" :command="i" :key="i" >{{i}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-btn :disabled="!loadAudio" size="mini" @click="takeMark">标注点</el-button>
          <el-button v-btn :disabled="!loadAudio" size="mini" @click="takeRegion">{{regionText}}</el-button>
          <el-button v-btn size="mini" @click="exportJson" :disabled="!currentAudio">导出文件</el-button>
          <el-button v-btn size="mini" @click="clickUpload">导入文件</el-button>
        </div>
        <div class="wrap">
          <div class="edit-zoom">
            <div class="label">
              间距缩放：
            </div>
            <div class="block">
              <el-slider
                :disabled="!loadAudio"
                @change="zoomChange"
                v-model="zoom"
                :min="1"
                show-tooltip
                :max="500">
              </el-slider>
            </div>
          </div>
          <div class="edit-voice">
            <div class="label">
              音量：
            </div>
            <div class="block">
              <el-slider
                :disabled="!loadAudio"
                @change="voiceChange"
                v-model="voice"
                :min="1"
                show-tooltip
                :max="100">
              </el-slider>
            </div>
          </div>
        </div>
      </div>
      <el-upload
        style="opacity: 0; width: 0; height: 0"
        action
        accept=".json"
        :auto-upload="false"
        :on-change="importJson"
      >
        <el-button class="" size="mini" @click="readyUpload">点击上传</el-button>
      </el-upload>
    </div>
    <div class="scroll">
      <div style="display: flex">
        <el-table :data="tableData" style="margin-right: 50px" max-height="300px">
          <el-table-column label="id" prop="id"></el-table-column>
          <el-table-column label="标记点时间(单位：秒)" prop="time"></el-table-column>
          <el-table-column label="注释">
            <template slot-scope="scope">
              <el-input class="markInput" size="mini" ref="markInput" v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button v-btn type="normal" size="mini" @click="delMarkers(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-table :data="regions" max-height="300px" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column label="id">
            <template slot-scope="scope">
              <span>{{regions[scope.$index].data.note}}</span>
            </template>
          </el-table-column>
          <el-table-column label="区域开始时间(单位：秒)" prop="start"></el-table-column>
          <el-table-column label="区域结束时间(单位：秒)" prop="end"></el-table-column>
          <el-table-column label="注释">
            <template slot-scope="scope">
              <el-input class="markInput" ref="markInput" size="mini" v-model="scope.row.data.remark"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button v-btn size="mini" @click="delRegions(scope.row.id, scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WaveSurfer from 'wavesurfer.js'
import MarkersPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.markers'
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions'
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline'
import FileSaver from 'file-saver'
import { allWav, lWav, data230518 } from '@/json/'
import { getFile, getAccountInfo } from '@/serve/module/common'

export default {
  data () {
    return {
      wavesurfer: null,
      markers: [],
      tableData: [],
      regionsMark: [],
      clickStart: false,
      regions: [],
      isStart: false,
      speeds: [
        0.25,
        0.5,
        0.75,
        1
      ],
      currentSpeed: 'X 1',
      regionText: '标注区域起点',
      audioList: [],
      currentAudio: '',
      loadAudio: false,
      zoom: 1,
      voice: 20,
      pointId: 0,
      regionId: 0,
      url: '',
      total: 0,
      doctorInfo: {},
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      author: '',
      username: ''
    }
  },
  mounted () {
    this.getAccountInfo()
  },
  methods: {
    async getAccountInfo () {
      try {
        const { data } = await getAccountInfo()
        this.username = data.username
        console.log('number:', data.username)
        const usernameNum = +data.username.replace('doctor', '')
        if (usernameNum === 1) { //  doctor1 账号
          this.audioList = allWav
        } else if (usernameNum === 2) { //  doctor2 账号
          this.audioList = lWav
        } else if (usernameNum >= 3 && usernameNum <= 7) { //  doctor3 ~ doctor7 账号
          this.audioList = data230518
        } else {
          // 其余账号为空
          this.audioList = []
        }
        this.total = this.audioList.length
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    reset () {
      this.regions = []
      this.regionId = 0
      this.pointId = 0
      this.clickStart = false
      this.isStart = false
      this.markers = []
      this.tableData = []
      this.currentSpeed = 'X 1'
      this.regionText = '标注区域起点'
      this.wavesurfer.clearRegions()
      this.wavesurfer.clearMarkers()
      this.wavesurfer.setPlaybackRate(1)
      this.wavesurfer.zoom(1)
      this.zoom = 1
      document.removeEventListener('keyup', this.skip, false)
    },
    init () {
      this.wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#418bcb',
        progressColor: '#30708c',
        audioRate: '1',
        height: 240,
        plugins: [
          MarkersPlugin.create(),
          RegionsPlugin.create(),
          TimelinePlugin.create({
            container: '#wave-timeline'
          })
        ],
        normalize: true
      })
      this.wavesurfer.setVolume(this.voice / 2)
    },
    async load () {
      this.reset()
      const currentAudio = this.currentAudio
      getFile(currentAudio).then(res => {
        if (res) {
          this.url = res.data.fileUrl

          console.log(this.url)
          this.wavesurfer.load(this.url)
          this.loading = this.$loading({
            lock: true,
            text: 'Loading...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.wavesurfer.on('region-updated', (evt) => {
            // console.log('32167:', evt.end)
            evt.start = +evt.start.toFixed(3)
            evt.end = +evt.end.toFixed(3)
          })
          this.wavesurfer.on('ready', () => {
            this.loading.close()
            document.addEventListener('keyup', this.skip, false)
            this.loadAudio = true
          })
          this.wavesurfer.on('error', (e) => {
            this.loading.close()
            console.log('e:', e)
            alert('网络错误')
          })
          this.voice = this.wavesurfer.getVolume() * 2

          this.wavesurfer.on('finish', () => {
            this.isStart = false
          })
        }
        console.log('step1')
      }).then(() => {
        console.log(document.getElementsByName('el-button'))
        window.addEventListener('keydown', this.handleKeyCode, true)
        console.log('step2')
      }).then(() => {
        return new Promise((resolve) => {
          console.log('step3')
          resolve()
        })
      })
    },
    handleKeyCode (e) {
      if (e.keyCode === 32 && document.activeElement.tagName === 'BODY') {
        this.play()
      }
    },
    chooseAudio () {
      if (!this.currentAudio) {
        return false
      }
      if (!this.wavesurfer) {
        this.init()
      } else {
        // this.wavesurfer.destroy()
        // this.wavesurfer.empty()
      }
      this.load()
    },
    play () {
      if (this.isStart) {
        this.pause()
        this.isStart = false
      } else {
        this.start()
        this.isStart = true
      }
    },
    start () {
      this.wavesurfer.play()
    },
    pause () {
      this.wavesurfer.pause()
    },
    stop () {
      this.wavesurfer.stop()
      this.isStart = false
    },
    takeMark () {
      const markTime = this.wavesurfer.getCurrentTime()
      this.wavesurfer.markers.add(
        {
          time: markTime,
          label: 'P' + this.pointId,
          color: '#ff990a',
          position: 'top',
          remark: ''
        }
      )
      this.tableData.push({
        time: +markTime.toFixed(3),
        id: 'P' + this.pointId
      })
      this.pointId++
    },
    delMarkers (index) {
      this.tableData.splice(index, 1)
      this.wavesurfer.markers.remove(index)
    },
    delRegions (id, index) {
      Object.keys(this.wavesurfer.regions.list).forEach(item => {
        if (this.wavesurfer.regions.list[item].id === id) {
          this.wavesurfer.regions.list[item].remove()
        }
        console.log(this.wavesurfer.regions.list[item])
      })

      this.regions.splice(index, 1)
      // this.wavesurfer.regions.list.forEach((item, index) => {
      //   console.log(item)
      //   console.log(index)
      // })
      // console.log(this.wavesurfer.regions.list)
      // this.wavesurfer.regions.list[index].remove()
    },
    takeRegion () {
      if (!this.clickStart) {
        const markTime = this.wavesurfer.getCurrentTime()
        this.regionsMark.push(markTime)
        this.clickStart = true
        this.regionText = '标注区域终点'
      } else {
        this.regionsEnd()
      }
    },
    regionsEnd () {
      if (!this.clickStart) {
        this.wavesurfer.pause()
        alert('请先选取起始位置')
        return false
      }
      const markTime = this.wavesurfer.getCurrentTime()
      const options = {
        id: `R${this.regionId}`,
        start: +this.regionsMark[0].toFixed(3),
        end: +markTime.toFixed(3),
        color: 'rgba(255, 0, 0, .5)',
        // color: 'green',
        attributes: {
          label: `R${this.regionId}`
        },
        data: {
          note: `R${this.regionId}`,
          remark: ''
        }
      }
      this.wavesurfer.addRegion(options)
      this.regionsMark = []
      this.regionId++
      this.clickStart = false
      this.regionText = '标记区域起点'
      this.regions = []
      for (const i in this.wavesurfer.regions.list) {
        this.regions.push(this.wavesurfer.regions.list[i])
      }

      // console.log(this.wavesurfer.regions.list);
      console.log(this.regions)
    },
    exportJson () {
      if (!this.currentAudio) {
        return false
      }

      const regions = []
      if (this.regions.length > 0) {
        this.regions.forEach(item => {
          console.log(item)
          regions.push({
            start: item.start,
            end: item.end,
            remark: item.data.remark || '',
            id: item.id
          })
        })
      }
      console.log()
      const data = JSON.stringify({
        type: 'heart-mark',
        name: this.currentAudio.split('.')[0],
        point: this.tableData,
        regions,
        author: this.author ? this.author : this.username,
        editor: this.username
      })

      const blob = new Blob([data], { type: '' })
      FileSaver.saveAs(blob, `${this.currentAudio.split('.')[0]}.json`)
    },
    importJson (file, fileList) {
      const reader = new FileReader()
      reader.readAsText(file.raw, 'UTF-8')
      reader.onload = (evt) => {
        const dataJson = JSON.parse(evt.target.result)
        console.log('dataJson:', dataJson)

        if (!dataJson.type || dataJson.type !== 'heart-mark') {
          const hasPoint = dataJson.hasOwnProperty('point')
          const hasRegions = dataJson.hasOwnProperty('regions')
          if (!hasPoint || !hasRegions) {
            alert('请导入正确的标注文件')
            return false
          }
        }

        if (this.audioList.indexOf(dataJson.name + '.wav') === -1) {
          alert('标注文件不属于该用户')
          return false
        }

        this.author = dataJson.author ? dataJson.author : ''

        this.currentAudio = dataJson.name + '.wav'
        if (!this.wavesurfer) {
          this.init()
        }
        setTimeout(() => {
          this.load()
          console.log(this.wavesurfer)
          const pointArr = []
          const regionArr = []
          if (dataJson.point.length > 0) {
            dataJson.point.forEach(i => {
              this.wavesurfer.markers.add({
                time: i.time,
                label: i.id,
                color: '#ff990a',
                position: 'top'
              })
              this.tableData.push(i)
              pointArr.push(parseInt(i.id.replace('P', '')))
            })
            this.pointId = Math.max(...pointArr) + 1
          }

          if (dataJson.regions.length > 0) {
            dataJson.regions.forEach(i => {
              this.wavesurfer.addRegion({
                id: i.id,
                start: +i.start.toFixed(3),
                end: +i.end.toFixed(3),
                color: 'rgba(255, 0, 0, .5)',
                attributes: {
                  label: i.id
                },
                data: {
                  note: i.id,
                  remark: i.remark
                }
              })
              this.regions.push(this.wavesurfer.regions.list[i.id])
              regionArr.push(parseInt(i.id.replace('R', '')))
            })
            this.regionId = Math.max(...regionArr) + 1
          }
        }, 100)
      }
    },
    clickUpload () {
      document.querySelector('.el-upload__input').click()
    },
    readyUpload () {
      console.log('123321')
    },
    handleCommand (command) {
      console.log(command)
      this.currentSpeed = 'X ' + command
      this.pause()
      this.wavesurfer.setPlaybackRate(command)
      this.start()
    },
    zoomChange (val) {
      this.wavesurfer.zoom(val)
    },
    voiceChange (val) {
      console.log('voice:', val)
      this.wavesurfer.setVolume(val / 2)
    },
    skip (e) {
      if (e.code === 'ArrowRight') {
        this.wavesurfer.skip(0.01)
      }
      if (e.code === 'ArrowLeft') {
        this.wavesurfer.skip(-0.01)
      }
    },
    handleCurrentChange (val) {
      Object.keys(this.wavesurfer.regions.list).forEach(item => {
        if (this.wavesurfer.regions.list[item].id === val.id) {
          this.$set(this.wavesurfer.regions.list[item], 'color', 'rgba(245, 233, 103, 0.5)')
        } else {
          this.$set(this.wavesurfer.regions.list[item], 'color', 'rgba(255, 0, 0, .5)')
        }
        this.wavesurfer.regions.list[item]._onRedraw()
      })
    },
    logout () {
      this.$router.replace({
        path: '/login'
      })
      window.sessionStorage.removeItem('Authorization')
    }
  }
}
</script>
<style scoped lang="scss">
  .home {
    height: 100%;
    overflow: hidden;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    .fixed {
      .header {
        display: flex;
        justify-content: space-between;
        .top {
          height: 40px;
          /*width: 80px;*/
          background-color: #f8faff;
          border-radius: 8px;
          .top-avatar {
            display: flex;
            align-items: center;
            .showList {
              cursor: pointer;
            }
            .top-avatar_img {
              margin-right: 10px;
            }
          }
        }
      }
    }
    .scroll {
      flex: 1;
    }
    .empty_tips {
      /*height: 300px;*/
      font-size: 40px;
      text-align: center;
    }
    #waveform, #wave-timeline {
      /*width: 750px;*/
    }
    .control-wrap {
      display: flex;
      .wrap {
        display: flex;
        justify-content: center;
        margin: 16px 16px 16px 0;
        text-align: center;
        .edit-zoom ,.edit-voice{
          display: flex;
          align-items: center;
          width: 300px;
          .label {
            margin-right: 20px;
          }
        }
        .block {
          width: 200px;
        }
        .audio-stop {
          background: url("~@/static/Stop.png") no-repeat;
          background-size: 100% 100%;
        }
        .select-speed {
          margin: 0 10px;
        }
      }
    }
  }

</style>
<style>
  region.wavesurfer-region {
    height: 93% !important;
    top: auto !important;
    bottom: 0;
  }
  region.wavesurfer-region::before {
    content: attr(data-region-label);
    position: absolute;
    top: -15px;
    left: 0;
    z-index: 10000;
    color: #000000;
    font-size: 12px;
  }
</style>
